<footer class="footer">
  <div class="footer__data">
    <div class="footer__data--copyright">
      &copy; 2023 - {{ year }} Todos los derechos reservados.

      <!-- Portfolio desarrollado por
      <a
        href="https://fabriziodev.ar"
        rel="noopener nofollow noreferrer"
        class="footer__data--copyright-a"
        target="_blank"
        >Fabrizio Dev</a
      > -->
    </div>

    <div class="footer__data--socials">
      <app-socials-icons></app-socials-icons>
    </div>

    <div class="footer__data--techs">
      <span class="footer__data--techs-description"
        >Tecnologias usadas para el portfolio:
        <div class="footer__data--techs-description-enlaces">
          <a
            href="https://angular.io/"
            target="_blank"
            data-tts="up"
            aria-label="Angular"
            rel="nofollow noopener noreferrer"
            ><i class="devicon-angularjs-plain"></i
          ></a>
          <a
            href="https://sass-lang.com/"
            target="_blank"
            data-tts="up"
            aria-label="Sass"
            rel="nofollow noopener noreferrer"
          >
            <i class="devicon-sass-original"></i>
          </a>
          <a
            href="https://www.typescriptlang.org/"
            target="_blank"
            data-tts="up"
            aria-label="Typescript"
            rel="nofollow noopener noreferrer"
          >
            <i class="devicon-typescript-plain"></i>
          </a>
          |
          <a
            href="https://laravel.com/"
            target="_blank"
            data-tts="up"
            aria-label="Laravel"
          >
            <i class="devicon-laravel-plain"></i>
          </a>
          <a
            href="https://www.php.net/"
            target="_blank"
            data-tts="up"
            aria-label="PHP"
          >
            <i class="devicon-php-plain"></i>
          </a>
          <a
            href="https://mysql.com/"
            target="_blank"
            data-tts="up"
            aria-label="MySQL"
          >
            <i class="devicon-mysql-plain"></i>
          </a>
        </div>
      </span>
    </div>
  </div>
</footer>
