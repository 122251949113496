<section id="tecnologias" class="habilidades">
  <div class="habilidades__info">
    <div class="habilidades__info--badge">
      <app-badges title="🧑‍💻 Lenguajes · Tecnologías"></app-badges>
    </div>
    <h2 class="habilidades__info--title">Lenguajes y tecnologías</h2>
  </div>
  <div class="habilidades__techs">
    <div class="habilidades__techs--actuales">
      <span class="habilidades__techs--actuales-title"
        >Lo que uso a diario</span
      >

      <!-- <i >Hola mundo</i> -->
      <div class="habilidades__techs--actuales-languages">
        <span data-tts="up" aria-label="Angular"
          ><i class="devicon-angularjs-plain"></i
        ></span>
        <span data-tts="up" aria-label="TypeScript"
          ><i class="devicon-typescript-plain"></i
        ></span>
        <span data-tts="up" aria-label="HTML5"
          ><i class="devicon-html5-plain"></i
        ></span>
        <span data-tts="up" aria-label="CSS3"
          ><i class="devicon-css3-plain"></i
        ></span>
        <span data-tts="up" aria-label="Spring Boot"
          ><i class="devicon-spring-plain"></i
        ></span>
        <span data-tts="up" aria-label="Java"
          ><i class="devicon-java-plain"></i
        ></span>
        <span data-tts="up" aria-label="MySQL"
          ><i class="devicon-mysql-plain"></i
        ></span>
        <span data-tts="up" aria-label="Nginx"
          ><i class="devicon-nginx-original"></i
        ></span>
        <span data-tts="up" aria-label="AWS"
          ><i class="devicon-amazonwebservices-plain"></i
        ></span>
        <span data-tts="up" aria-label="Ubuntu"
          ><i class="devicon-ubuntu-plain"></i
        ></span>
        <span data-tts="up" aria-label="FileZilla"
          ><i class="devicon-filezilla-plain"></i
        ></span>

        <span data-tts="up" aria-label="Git"
          ><i class="devicon-git-plain"></i
        ></span>
      </div>
    </div>
    <div class="habilidades__techs--otras">
      <span class="habilidades__techs--otras-title">
        <!-- Otras que uso a menudo -->
        Otras que suelo usar a veces
      </span>
      <div class="habilidades__techs--otras-languages">
        <span data-tts="up" aria-label="React">
          <i class="devicon-react-original"></i>
        </span>
        <span data-tts="up" aria-label="Sass"
          ><i class="devicon-sass-original"></i
        ></span>
        <span data-tts="up" aria-label="Bootstrap"
          ><i class="devicon-bootstrap-plain"></i
        ></span>
        <span data-tts="up" aria-label="Laravel"
          ><i class="devicon-laravel-plain"></i
        ></span>
        <span data-tts="up" aria-label="PHP"
          ><i class="devicon-php-plain"></i
        ></span>
        <span data-tts="up" aria-label="JavaScript"
          ><i class="devicon-javascript-plain"></i
        ></span>
        <span data-tts="up" aria-label="NodeJS"
          ><i class="devicon-nodejs-plain"></i
        ></span>
        <span data-tts="up" aria-label="Express"
          ><i class="devicon-express-original"></i
        ></span>
        <span data-tts="up" aria-label=".NET Core"
          ><i class="devicon-dotnetcore-plain"></i
        ></span>
        <span data-tts="up" aria-label="C#"
          ><i class="devicon-csharp-plain"></i
        ></span>
        <span data-tts="up" aria-label="PostgreSQL">
          <i class="devicon-postgresql-plain"></i
        ></span>
        <span data-tts="up" aria-label="MongoDB"
          ><i class="devicon-mongodb-plain" title="MongoDB"></i
        ></span>
        <span data-tts="up" aria-label="Docker"
          ><i class="devicon-docker-plain"></i
        ></span>
        <span data-tts="up" aria-label="Apache"
          ><i class="devicon-apache-line"></i
        ></span>
        <span data-tts="up" aria-label="Tomcat"
          ><i class="devicon-tomcat-line"></i
        ></span>
        <span data-tts="up" aria-label="Windows"
          ><i class="devicon-windows8-plain"></i
        ></span>
      </div>
    </div>
  </div>
</section>
