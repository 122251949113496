<section id="experiencie" class="experiencie">
  <div class="experiencie__cards">
    <div class="experiencie__cards--card">
      <img src="../../../assets/img/code.svg" alt="Programador" width="10" />
      <span>1 año como</span>
      <p>Programador</p>
    </div>

    <div class="experiencie__cards--card experiencie__cards--card--background">
      <img src="../../../assets/img/design.svg" alt="Trabajo en IT" />
      <span>6 años de</span>
      <p>Trabajo en IT</p>
    </div>

    <div class="experiencie__cards--card">
      <img src="../../../assets/img/project.svg" alt="Devops" />
      <span>1 año como</span>
      <p>DevOps</p>
    </div>
  </div>

  <div class="experiencie__card">
    <div class="experiencie__card--card">
      <span> Desarrollador </span>
      <p>Full Stack</p>
    </div>
    <div class="experiencie__card--card">
      <span> Varios proyectos y </span>
      <p>Experiencias</p>
    </div>
    <div class="experiencie__card--card">
      <span> Creando nuevos </span>
      <p>Proyectos</p>
    </div>
  </div>
</section>
