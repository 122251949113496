import { Component } from '@angular/core';

@Component({
  selector: 'app-techs-skills',
  templateUrl: './techs-skills.component.html',
  styleUrls: ['./techs-skills.component.scss']
})
export class TechsSkillsComponent {

}
