<nav class="nav">
  <div class="nav__logo">
    <a href="/">
      <img src="../assets/img/logo.png" alt="Logo Fabrizio Dev" />
    </a>
    <svg class="nav__logo--icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" id="apps" (click)="toggleMenu()">
      <path
        d="M10,13H3a1,1,0,0,0-1,1v7a1,1,0,0,0,1,1h7a1,1,0,0,0,1-1V14A1,1,0,0,0,10,13ZM9,20H4V15H9ZM21,2H14a1,1,0,0,0-1,1v7a1,1,0,0,0,1,1h7a1,1,0,0,0,1-1V3A1,1,0,0,0,21,2ZM20,9H15V4h5Zm1,4H14a1,1,0,0,0-1,1v7a1,1,0,0,0,1,1h7a1,1,0,0,0,1-1V14A1,1,0,0,0,21,13Zm-1,7H15V15h5ZM10,2H3A1,1,0,0,0,2,3v7a1,1,0,0,0,1,1h7a1,1,0,0,0,1-1V3A1,1,0,0,0,10,2ZM9,9H4V4H9Z"
      ></path>
    </svg>
  </div>
  <div class="nav__menu">
    <ul>
      <li><a href="#inicio" class="">Inicio</a></li>
      <li><a href="#sobre-mi">Sobre mi</a></li>
      <li><a href="#proyectos">Proyectos</a></li>
      <li><a href="#certificados">Certificados</a></li>
      <li><a href="#tecnologias">Tecnologias</a></li>
      <li><a href="#carrera">Mi carrera</a></li>
      <li><a href="#contactame">Contactame</a></li>
    </ul>
  </div>
</nav>
<div class="nav__menu--movil" [ngClass]="{ 'open': isMenuOpen, 'closed': !isMenuOpen  }">
<div class="nav__menu--movil-close" (click)="toggleMenu()">
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" id="multiply"><path fill="currentColor"  d="M13.41,12l6.3-6.29a1,1,0,1,0-1.42-1.42L12,10.59,5.71,4.29A1,1,0,0,0,4.29,5.71L10.59,12l-6.3,6.29a1,1,0,0,0,0,1.42,1,1,0,0,0,1.42,0L12,13.41l6.29,6.3a1,1,0,0,0,1.42,0,1,1,0,0,0,0-1.42Z"></path></svg>
</div>
<div class="nav__menu--movil-data">
  <img src="../../../assets/img/yo.png" alt="">
  <span>Fabrizio Ferroni</span>
  <nav>
    <ul>
      <li><a href="#inicio" (click)="closeMenu()">Inicio</a></li>
      <li><a href="#sobre-mi" (click)="closeMenu()">Sobre mi</a></li>
      <li><a href="#proyectos" (click)="closeMenu()">Proyectos</a></li>
      <li><a href="#certificados" (click)="closeMenu()">Certificados</a></li>
      <li><a href="#tecnologias" (click)="closeMenu()">Tecnologias</a></li>
      <li><a href="#carrera" (click)="closeMenu()">Mi carrera</a></li>
      <li><a href="#contactame" (click)="closeMenu()">Contactame</a></li>
    </ul>
  </nav>
</div>
</div>

