import { Component } from '@angular/core';

@Component({
  selector: 'app-socials-icons',
  templateUrl: './socials-icons.component.html',
  styleUrls: ['./socials-icons.component.scss']
})
export class SocialsIconsComponent {

}
