<section id="certificados" class="certificados">
  <div class="certificados__info">
    <div class="certificados__info--titles">
      <h2 class="certificados__info--title">Mis certificados</h2>
      <span class="certificados__info--description"
        >Siempre es bueno saber un poco sobre mis certificados obtenidos.</span
      >
    </div>

    <div
      class="certificados__info--arrows"
      *ngIf="(certificate | certificates : page : limit).length > 0"
    >
      <button
        class="certificados__info--arrows--links"
        (click)="lastPage()"
        [disabled]="isFirstPage()"
        aria-label="Anterior"
        data-tts="up"
      >
        &lt;
      </button>
      <button
        class="certificados__info--arrows--links"
        (click)="nextPage()"
        [disabled]="isLastPage()"
        aria-label="Siguiente"
        data-tts="up"
      >
        &gt;
      </button>
    </div>
  </div>
  <div class="certificados__data">
    <div class="certificados__data--cards">
      <div
        class="certificados__data--cards-card"
        *ngFor="let item of certificate | certificates : page : limit"
      >
        <img
          [src]="item.imagen"
          [alt]="item.titulo"
          class="certificados__data--cards-card-img"
        />
        <div class="certificados__data--cards-card-1">
          <p class="certificados__data--cards-card-1-p" title="{{ item.titulo }}">{{ item.titulo | truncate: [28, '...'] }}</p>
          <div class="certificados__data--cards-card-1-tags">
            <span
              *ngFor="let tag of item.tags"
              class="certificados__data--cards-card-1-tags-tag"
            >
              {{ tag }}
            </span>
          </div>
        </div>
        <div class="certificados__data--cards-card-2">
          <span class="certificados__data--cards-card-2-inst">
            {{ item.academia }}
          </span>
          <span class="certificados__data--cards-card-2-fecha">
            {{ item.rango_fecha }}
          </span>
        </div>
        <div class="certificados__data--cards-card-download">
          <button
            *ngIf="!item.btn_load"
            (click)="descargarCert(item)"
            class="certificados__data--cards-card-download-button"
          >
            Descargar Certificado
          </button>

          <button
            *ngIf="item.btn_load"
            class="certificados__data--cards-card-download-button disabled"
            [disabled]
          >
            <div class="loadingio-spinner-rolling-j5etwe7pi5k">
              <div class="ldio-s45l4gig2e">
                <div></div>
              </div>
            </div>
            {{ item.mensaje_btn }}
          </button>
        </div>
      </div>
    </div>

    <div class="loading  {{ class }}">
      <div class="loading__certificados">
        <div class="loadingio-spinner-rolling-gtg2h2xv46o">
          <div class="ldio-hl7hapmg3r9">
            <div></div>
          </div>
        </div>
        <p>Espere por favor, estamos cargando los certificados...</p>
      </div>
    </div>
  </div>

  <div class="no_certificates {{ class_empty }}" *ngIf="certificate.length < 1">
    <div class="no_certificates__data">
      <p>😢</p>
      <span>Aún no se ha subido un certificado.</span>
    </div>
  </div>
</section>
