import { Component } from '@angular/core';

@Component({
  selector: 'app-experiencie',
  templateUrl: './experiencie.component.html',
  styleUrls: ['./experiencie.component.scss']
})
export class ExperiencieComponent {

}
