<section id="inicio" class="inicio">
  <div class="inicio__movil">
    <app-socials-icons></app-socials-icons>
  </div>
  <div class="inicio__info">
    <div class="inicio__info--badge">
      <app-badges title="👋 Saludos!"></app-badges>
    </div>
    <h1 class="inicio__info--title">Fabrizio Ferroni</h1>
    <span class="inicio__info--description"
      >Desarrollador web Full Stack Junior</span
    >
    <div class="inicio__info--socials">
      <app-socials-icons></app-socials-icons>
    </div>
  </div>
  <img
    src="../../../assets/img/logo_main-old.svg"
    alt="Logo Fabrizio Ferroni & techs"
    class="inicio__logo"
  />
  <!--test1-->

  <div class="inicio__download">
    <button
      (click)="downloadCv(cv)"
      *ngIf="!cv.btn_load"
      class="inicio__download--download"
      data-tts="up"
      aria-label="Descargar mi cv"
    >
      Descargar CV <i class="uil uil-import"></i>
    </button>
    <button
      *ngIf="cv.btn_load"
      class="inicio__download--download disabled"
      data-tts="up"
      aria-label="Descargar mi cv"
      disabled
    >
      {{ cv.mensaje_btn }} <i class="uil uil-import"></i>
    </button>

    <div class="{{ class_dcv }} toast-inline toast-inline--{{ type_dcv }}">
      {{ msg_resp }}
    </div>
    <a
      href="#contactame"
      class="inicio__download--contact"
      data-tts="up"
      aria-label="Mandame un mensaje"
      ><i class="uil uil-envelope"></i> Vamos a conversar</a
    >
  </div>
</section>
