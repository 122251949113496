<section id="sobre-mi" class="sobremi">
  <div class="sobremi__profile">
    <img
      src="../../../assets/img/profile.png"
      alt="Fabrizio Ferroni foto perfil"
    />
  </div>
  <div class="sobremi__info">
    <div class="sobremi__info--badge">
      <app-badges title="🧐 Sobre mi"></app-badges>
    </div>
    <div class="sobremi__info--title">Fabrizio Ferroni</div>
    <div class="sobremi__info--description">
      <p>
        <!-- 👋 Hola, un placer saludarte. Soy un apasionado de la tecnología con
        {{ calcularEdad() }} años, residiendo actualmente en Valencia 🇪🇸. Puedes
        dirigirte a mí como Fabrii. -->
        <!-- 👋 Tengo {{ calcularEdad() }} años y actualmente me encuentro viviendo en Valencia 🇪🇸. Mi
        nombre es Fabrizio Ferroni, pero puedes llamarme Fabrizio. ¡Un gusto! -->

        👋 Hola, soy Fabrii, un desarrollador web de {{ calcularEdad() }} años
        que vive en Valencia, 🇪🇸. Me apasiona la tecnología y siempre estoy
        aprendiendo cosas nuevas.
      </p>
      <p>
        <!-- 👨‍💻 Durante el último año, me he dedicado a estudiar sobre el desarrollo
        y programación de proyectos y aplicaciones personales utilizando
        tecnologías como Angular, Spring Boot y MySQL. -->
        <!-- 👨‍💻 Durante el último año, he dedicado mi tiempo al estudio y desarrollo
        de proyectos y aplicaciones personales, explorando tecnologías como
        Angular, Spring Boot y MySQL.  -->

        <!-- 👨‍💻 Desde el 2017, mi trayectoria se ha
        centrado en el estudio y desarrollo de proyectos y aplicaciones
        personales, explorando diversas tecnologías que incluyen, entre otras,
        Angular, Spring Boot, MySQL y otras herramientas vanguardistas. -->

        👨‍💻 Mi camino en el mundo tecnológico comenzó en 2017, cuando aprendí
        HTML, CSS, JavaScript y PHP. Desde entonces, he ampliado mis
        conocimientos en diversas tecnologías, incluyendo Angular, Spring Boot y
        MySQL.

        <!-- Mi camino en el mundo tecnológico comenzó en 2017, adentrándome en
        las aguas de PHP, JavaScript, MySQL, HTML y CSS. Desde entonces, he
        continuado explorando y expandiendo mis conocimientos en diversas
        tecnologías, incluyendo Angular, Spring Boot y más. -->
      </p>
      <p>
        <!-- 🎓 Graduado en Bachillerato en Informática en Instituto La Santisima
        Trinidad. -->
        🎓 Estoy graduado en Bachiller en Informática en el Instituto La
        Santísima Trinidad

        <!-- Estoy graduado en Bachiller en Informática en el Instituto La
        Santísima Trinidad. -->
      </p>
      <p>
        <!-- 💡 Intereses en desarrollo Front-end con Angular, Backend con Spring
        Boot (Java), .NET (C#), NodeJS (Javascript), Laravel (PHP), Base de
        datos como MySQL, PostgreSQL, MongoDB y DevOps. -->

        💬 Mis principales intereses se centran en el desarrollo web, con
        especial énfasis en el desarrollo Front-end con Angular y en el
        desarrollo Back-end con tecnologías como Spring Boot (Java), Laravel (PHP), .NET (C#) y
        NodeJS (JavaScript). Además, tengo experiencia con bases de datos como
        MySQL, PostgreSQL y MongoDB, así como conocimientos en DevOps.

        <!-- Mis principales intereses se centran en el desarrollo Front-end con
        Angular y en el Back-end con tecnologías como Spring Boot (Java), .NET
        (C#), NodeJS (JavaScript) y Laravel (PHP). Además, tengo experiencia con
        bases de datos como MySQL, PostgreSQL y MongoDB, así como conocimientos
        en DevOps. -->
      </p>
      <p>
        💡 Soy una persona creativa y siempre estoy buscando nuevas formas de
        resolver problemas. Me encanta trabajar en equipo y estoy siempre
        dispuesto a ayudar a los demás.
      </p>
      <p>
        <!-- 🚀 Tratando de ser un poco mejor que ayer todos los días. -->
        🚀 Mi lema de vida es: "Trato de superarme un poco cada día, buscando aprender y crecer constantemente".


        <!-- Mi lema de vida: trato de superarme un poco cada día, buscando
        aprender y crecer constantemente. ¡Encantado de conocerte! -->
      </p>
    </div>
  </div>
</section>
