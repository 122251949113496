<section id="contactame" class="contactame">
  <div class="contactame__info">
    <div class="contactame__info--badge">
      <app-badges title="📬 Contactame"></app-badges>
    </div>
    <h2 class="contactame__info--title">Vamos a conversar!</h2>
  </div>
  <div class="contactame__data">
    <div class="contactame__data--info">
      <!-- Email -->
      <div class="contactame__data--info-container">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          data-name="Layer 1"
          viewBox="0 0 24 24"
          id="envelope-upload"
        >
          <path
            fill="#0092E4"
            d="M20.5,14a1,1,0,0,0-1,1v4a1,1,0,0,1-1,1H4.5a1,1,0,0,1-1-1V9.41L9.38,15.3a3,3,0,0,0,4.24,0l1.64-1.64a1,1,0,1,0-1.42-1.42L12.2,13.88a1,1,0,0,1-1.4,0L4.91,8H11.5a1,1,0,0,0,0-2h-7a3,3,0,0,0-3,3V19a3,3,0,0,0,3,3h14a3,3,0,0,0,3-3V15A1,1,0,0,0,20.5,14Zm1.71-8.71-3-3a1,1,0,0,0-.33-.21,1,1,0,0,0-.76,0,1,1,0,0,0-.33.21l-3,3a1,1,0,0,0,1.42,1.42l1.29-1.3V11a1,1,0,0,0,2,0V5.41l1.29,1.3a1,1,0,0,0,1.42,0A1,1,0,0,0,22.21,5.29Z"
          ></path>
        </svg>
        <span class="contactame__data--info-container-tipo">Email</span>

        <div class="contactame__data--info-container-enlaces">
          <a
            href="mailto:hola@fabriziodev.ar"
            class="contactame__data--info-container-enlaces-a"
            id="copyEmail"
            #copyEmail
            >hola@fabriziodev.ar</a
          >
          <span
            class="contactame__data--info-container-enlaces-copy"
            (click)="copyToClipboard(extractEmailAddress(copyEmail.href))"
            data-tts="up"
            aria-label="Copiar al portapapel"
            ><i class="uil uil-copy"></i
          ></span>
        </div>
      </div>
      <!-- Fin Email -->

      <!-- Linkedin -->
      <div class="contactame__data--info-container">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          data-name="Layer 1"
          viewBox="0 0 24 24"
          id="linkedin"
        >
          <path
            fill="#0092E4"
            d="M20.47,2H3.53A1.45,1.45,0,0,0,2.06,3.43V20.57A1.45,1.45,0,0,0,3.53,22H20.47a1.45,1.45,0,0,0,1.47-1.43V3.43A1.45,1.45,0,0,0,20.47,2ZM8.09,18.74h-3v-9h3ZM6.59,8.48h0a1.56,1.56,0,1,1,0-3.12,1.57,1.57,0,1,1,0,3.12ZM18.91,18.74h-3V13.91c0-1.21-.43-2-1.52-2A1.65,1.65,0,0,0,12.85,13a2,2,0,0,0-.1.73v5h-3s0-8.18,0-9h3V11A3,3,0,0,1,15.46,9.5c2,0,3.45,1.29,3.45,4.06Z"
          ></path>
        </svg>
        <span class="contactame__data--info-container-tipo">Linkedin</span>

        <div class="contactame__data--info-container-enlaces">
          <a
            href="https://www.linkedin.com/in/fabrizio-ferroni/"
            class="contactame__data--info-container-enlaces-a"
            id="copyLinkedin"
            #copyLinkedin
            target="_blank"
            rel="noopener nofollow noreferrer"
            >In/FabrizioFerroni</a
          >
          <span
            class="contactame__data--info-container-enlaces-copy"
            data-tts="up"
            (click)="copyToClipboard(copyLinkedin.href)"
            aria-label="Copiar al portapapel"
            ><i class="uil uil-copy"></i
          ></span>
        </div>
      </div>
      <!-- Fin Linkedin -->

      <!-- Github -->
      <div class="contactame__data--info-container">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          data-name="Layer 1"
          viewBox="0 0 24 24"
          id="github"
        >
          <path
            fill="#0092E4"
            d="M12,2.2467A10.00042,10.00042,0,0,0,8.83752,21.73419c.5.08752.6875-.21247.6875-.475,0-.23749-.01251-1.025-.01251-1.86249C7,19.85919,6.35,18.78423,6.15,18.22173A3.636,3.636,0,0,0,5.125,16.8092c-.35-.1875-.85-.65-.01251-.66248A2.00117,2.00117,0,0,1,6.65,17.17169a2.13742,2.13742,0,0,0,2.91248.825A2.10376,2.10376,0,0,1,10.2,16.65923c-2.225-.25-4.55-1.11254-4.55-4.9375a3.89187,3.89187,0,0,1,1.025-2.6875,3.59373,3.59373,0,0,1,.1-2.65s.83747-.26251,2.75,1.025a9.42747,9.42747,0,0,1,5,0c1.91248-1.3,2.75-1.025,2.75-1.025a3.59323,3.59323,0,0,1,.1,2.65,3.869,3.869,0,0,1,1.025,2.6875c0,3.83747-2.33752,4.6875-4.5625,4.9375a2.36814,2.36814,0,0,1,.675,1.85c0,1.33752-.01251,2.41248-.01251,2.75,0,.26251.1875.575.6875.475A10.0053,10.0053,0,0,0,12,2.2467Z"
          ></path>
        </svg>
        <span class="contactame__data--info-container-tipo">Github</span>

        <div class="contactame__data--info-container-enlaces">
          <a
            href="https://github.com/FabrizioFerroni"
            class="contactame__data--info-container-enlaces-a"
            id="copyGit"
            #copyGit
            target="_blank"
            rel="noopener nofollow noreferrer"
            >FabrizioFerroni</a
          >
          <span
            class="contactame__data--info-container-enlaces-copy"
            data-tts="up"
            (click)="copyToClipboard(copyGit.href)"
            aria-label="Copiar al portapapel"
            ><i class="uil uil-copy"></i>
            <!-- <i class="uil uil-check"></i> -->
          </span>
        </div>
      </div>
      <!-- Fin Github -->
    </div>
    <div class="contactame__data--forms">
      <form
        method="POST"
        #form="ngForm"
        class="contactame__data--forms-form"
        id="contactForm"
        (submit)="contacto(form)"
      >
        <span class="contactame__data--forms-form-valid">
          <i class="uil uil-check"></i> Se envio el mensaje con éxito. Le
          responderé a la brevedad
        </span>

        <span class="contactame__data--forms-form-error">
          <i class="uil uil-times"></i> Upps.. hubo un error al enviar su
          mensaje. Intente nuevamente
        </span>

        <span class="contactame__data--forms-form-warning">
          <i class="uil uil-exclamation-triangle"></i> Upps.. debe rellenar
          todos los campos para poder enviar el formulario
        </span>
        <div class="contactame__data--forms-form-div">
          <div class="contactame__data--forms-form-group-inputs">
            <div>
              <div class="contactame__data--forms-form-group-inputs-individual">
                <label for="nombre">Nombre</label>
                <input
                  type="text"
                  name="nombre"
                  id="nombre"
                  [(ngModel)]="contactoForm.nombre"
                  placeholder="Ingrese su nombre"
                  required
                  autocomplete="off"
                />
              </div>
              <span class="is-invalid error">
                <i class="uil uil-times"></i>
                Tienes q completar el nombre</span
              >
            </div>
            <div>
              <div class="contactame__data--forms-form-group-inputs-individual">
                <label for="apellido">Apellido</label>
                <input
                  type="text"
                  name="apellido"
                  id="apellido"
                  [(ngModel)]="contactoForm.apellido"
                  placeholder="Ingrese su apellido"
                  required
                  autocomplete="off"
                />
              </div>
              <span class="is-invalid error">
                <i class="uil uil-times"></i>
                Tienes q completar el apellido</span
              >
            </div>
          </div>
          <div class="contactame__data--forms-form-group-inputs">
            <div>
              <div class="contactame__data--forms-form-group-inputs-individual">
                <label for="email">Correo Electrónico</label>
                <input
                  type="email"
                  name="email"
                  [(ngModel)]="contactoForm.email"
                  id="email"
                  placeholder="Ingrese su correo electrónico"
                  required
                  autocomplete="off"
                />
              </div>
              <span class="is-invalid error">
                <i class="uil uil-times"></i>
                Tienes q completar el correo</span
              >
            </div>
            <div>
              <div class="contactame__data--forms-form-group-inputs-individual">
                <label for="telefono">Telefono</label>
                <input
                  type="tel"
                  name="telefono"
                  id="telefono"
                  onkeypress="return (event.charCode >= 48 && event.charCode <= 57)"
                  [(ngModel)]="contactoForm.telefono"
                  placeholder="Ingrese su número de telefono"
                  required
                  autocomplete="off"
                />
              </div>
              <span class="is-invalid error">
                <i class="uil uil-times"></i>
                Tienes q completar el telefono</span
              >
            </div>
          </div>
          <div>
            <div class="contactame__data--forms-form-input">
              <label for="asunto">Asunto</label>
              <input
                type="text"
                name="asunto"
                id="asunto"
                [(ngModel)]="contactoForm.asunto"
                required
                placeholder="Indique un asunto de su consulta"
                autocomplete="off"
              />
            </div>
            <span class="is-invalid error">
              <i class="uil uil-times"></i>
              Tienes q completar el asunto</span
            >
          </div>
          <div>
            <div class="contactame__data--forms-form-textarea">
              <label for="mensaje">Mensaje</label>
              <textarea
                name="mensaje"
                id="mensaje"
                [(ngModel)]="contactoForm.mensaje"
                placeholder="Deje aqui su mensaje"
                required
              ></textarea>
            </div>
            <span class="is-invalid error">
              <i class="uil uil-times"></i>
              Tienes q completar el mensaje</span
            >
          </div>
          <div class="contactame__data--forms-form-button">
            <button type="submit" *ngIf="!btn_load" [disabled]="form.invalid">
              <i class="uil uil-message"></i>
              Enviar
            </button>

            <button
              type="button"
              *ngIf="btn_load"
              [disabled]="!form.valid"
              class="disabled flex boton--loading"
            >
              <div class="loadingio-spinner-rolling-j5etwe7pi5k">
                <div class="ldio-s45l4gig2e">
                  <div></div>
                </div>
              </div>
              {{ mensaje_btn }}
            </button>

            <span
              class="{{
                display
              }} contactame__data--forms-form-message contactame__data--forms-form-message-{{
                type
              }}"
              >{{ mensaje }}</span
            >
          </div>
        </div>
      </form>
    </div>
  </div>
</section>
