<div class="socials">
  <a
    href="https://www.linkedin.com/in/fabrizio-ferroni/"
    target="_blank"
    rel="nofollow noopener noreferrer"
    data-tts="up"
    aria-label="Linkedin"
  >
    <i class="uil uil-linkedin-alt"></i>
  </a>
  <a
    href="https://github.com/FabrizioFerroni"
    target="_blank"
    rel="nofollow noopener noreferrer"
    data-tts="up"
    aria-label="Github"
  >
    <i class="uil uil-github-alt"></i>
  </a>
  <a
    href="mailto:hola@fabriziodev.ar"
    target="_blank"
    rel="nofollow noopener noreferrer"
    data-tts="up"
    aria-label="Contactame"
  >
    <i class="uil uil-envelope"></i>
  </a>
</div>
