<app-nav></app-nav>
<app-home></app-home>
<app-experiencie></app-experiencie>
<app-about></app-about>
<app-proyects></app-proyects>
<app-certificates></app-certificates>
<app-techs-skills></app-techs-skills>
<app-carrer></app-carrer>
<!-- <app-testimonials></app-testimonials> -->
<app-contact></app-contact>
<app-footer></app-footer>
